<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="left">
				<!-- 导航 -->
				<div class="nav">
					<div :class="['item', item.index == navindex ? 'itemClick' : '']" v-for="(item, index) in nav"
						:key="index" @mouseenter="naventer(item.index)" @mouseleave="navleave()"
						@click="navChange(item.index)">
						<div class="name">
							<img
								:src="item.index == navindex || item.index == navindexHover ? item.iconc: item.icon" />{{ item.name }}
						</div>
						<div><i class="el-icon-arrow-right"></i></div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="conter1" v-if="navindex === 0">
					<!-- 个人信息 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />个人信息
							</div>
							<div class="moduleEdit" @click="openwin(10, '')" v-if="editBtn">编辑</div>
						</div>
						<div class="label flex1" style="padding-top: 10px;">
							<div class="module-left">
								<div class="pic">
									<img :src="user.peR_IMG_PATH? setuserlogo(user.peR_IMG_PATH): require('@/assets/img/tx.png')"
										alt="" />
								</div>
							</div>
							<div class="module-right">
								<div class="name flex1">
									{{newuser.PER_NAME}}
									<span class="red" v-if="user.peR_SH==0">简历未审核</span>
								</div>
								<div class="flex1">
									<span>{{ newuser.PER_GENDER}}</span>
									<span>|</span>
									<span>{{calculationage(newuser.PER_BIRTHDAY)}}岁</span>
									<span>|</span>
									<span>{{newuser.PER_WORK_YEARS>0?newuser.PER_WORK_YEARS+"年":"无"}}工作经验</span>
									<span>|</span>
									<span>{{ geteducationlist(newuser.PER_HEDU) }}</span>
								</div>
								<div class="flex1" style="margin-top: 10px;font-size: 16px;">
									<span>
										<i class="el-icon-mobile-phone"></i>
										{{newuser.PER_CELLPHONE}}
									</span>
									<span style="margin-left:67px;">
										<i class="el-icon-message"></i>
										{{ newuser.PER_EMAIL}}
									</span>
								</div>
							</div>
						</div>
						<div class="moduleBTN" v-if="btn">
							<div class="cancel" @click="cancel()">取消</div>
							<div class="preservation" @click="saveresume((res) => {editBtn = true;btn = false;})">
								提交
							</div>
						</div>
					</div>
					<!-- 消息 -->
					<div class="message">
						<div class="flex1" style="margin-left: 8px">
							<img class="message-i" src="@/assets/img/message.png" alt="" />
							{{messageCount? "您有" + messageCount + "条新消息提醒，可前往小程序查看": "暂无最新消息，可前往小程序同企业进行一对一沟通"}}
						</div>
						<div class="message-right">
							<img @click="getmessagelist();Mdialog = true" class="message-r" slot="reference"
								src="@/assets/img/sm.png" alt="" />
							<!-- <div class="badge" v-if="messageCount">
								{{ messageCount > 99 ? 99 : messageCount }}
							</div> -->
						</div>
					</div>
					<!-- 期望职位 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />求职期望
							</div>
							<div class="moduleEdit" @click="openwin(4, '')" v-if="worklist.length<3">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in worklist">
								<div class="flex">
									<div class="expectLeft">
										<div class="expectName">{{ item.name }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item,4)">编辑</div>
										<div class="expectDel" @click="expectEork(item)">删除</div>
									</div>
								</div>
								<div class="flex1">
									<div class="expectLeft">
										<div>期望薪资：{{setsalary(item.salary, item.salarye)}}</div>
										<div>工作性质：{{ item.nature }}</div>
									</div>
									<div class="expectLeft">
										<div>
											期望城市：{{ CodeToText(item.percode)}}/{{ CodeToText(item.citycode) }}/{{ CodeToText(item.areacode) }}
										</div>
										<div>期望行业：{{ item.hyName}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- 自我评价 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />自我评价
							</div>
							<div class="moduleEdit" @click="openwin(1, '')">编辑</div>
						</div>
						<div class="label">
							<div class="labelItem" v-html="return2Br(user.peR_INTRODUCE)"></div>
						</div>
					</div>
					<!-- 职业技能 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />职业技能
							</div>
							<div class="moduleEdit" @click="openwin(11, '')">编辑</div>
						</div>
						<div class="label">
							<div class="labelItem" v-html="return2Br(user.peR_JNTC_INTRODUCE)"></div>
						</div>
					</div>

					<!-- 工作经历 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />工作经历
							</div>
							<div class="moduleEdit" @click="openwin(5, '')">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in infolist1">
								<div class="flex">
									<div class="expectLeft flex1">
										<div class="expectName">{{ item.title }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item,1)">编辑</div>
										<div class="expectDel" @click="expectDel(item)">删除</div>
									</div>
								</div>
								<div class="flex">
									<div class="expectLeft flex1">
										<div>{{ item.name }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectSalary">
											{{stringToDates(item.stateTime) }}-{{ stringToDates(item.endTime) }}
										</div>
									</div>
								</div>
								<div class="expectCont" v-html="return2Br(item.infoDes)"></div>
							</div>
						</div>
					</div>
					<!-- 项目经历 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />项目经历
							</div>
							<div class="moduleEdit" @click="openwin(9, '')">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in infolist3">
								<div class="flex">
									<div class="expectLeft flex1">
										<div class="expectName">{{ item.title }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item,3)">编辑</div>
										<div class="expectDel" @click="expectDel(item)">删除</div>
									</div>
								</div>
								<div class="flex">
									<div class="expectLeft flex1">
										<div>{{ item.name }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectSalary">
											{{stringToDates(item.stateTime) }}-{{ stringToDates(item.endTime) }}
										</div>
									</div>
								</div>
								<div class="expectCont" v-html="return2Br(item.infoDes)"></div>
							</div>
						</div>
					</div>
					<!-- 教育经历 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />教育经历
							</div>
							<div class="moduleEdit" @click="openwin(6, '')">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in infolist2">
								<div class="flex">
									<div class="expectLeft flex1">
										<div class="expectName">{{ item.title }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item,2)">编辑</div>
										<div class="expectDel" @click="expectDel(item)">删除</div>
									</div>
								</div>
								<div class="flex">
									<div class="expectLeft flex1">
										<div>{{ item.name }} | {{item.infoDes}}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectSalary">
											{{ stringToDates(item.endTime) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 职称证书 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />职称证书
							</div>
							<div class="moduleEdit" @click="openwin(12, '')">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in infolist9">
								<div class="flex">
									<div class="expectLeft flex1">
										<div class="expectName">{{ item.title }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item, 9)">编辑</div>
										<div class="expectDel" @click="expectDel(item)">删除</div>
									</div>
								</div>
								<div class="flex">
									<div class="expectLeft flex1">
										<!-- <div>{{ item.name }} | {{item.infoDes}}</div> -->
										<div>{{ item.name }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectSalary">
											{{ stringToDates(item.endTime) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 简历附件 -->
					<div class="module">
						<div class="flex4">
							<div class="moduleTitle flex1">
								<img src="../../assets/img/icon.png" alt="" />简历附件
							</div>
							<div class="moduleEdit" @click="openwin(13, '')">新增</div>
						</div>
						<div class="label">
							<div class="expect" v-for="item in infolist10">
								<div class="flex">
									<div class="expectLeft flex1">
										<div class="expectName">{{ item.title }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectEdit" @click="expectEdit(item, 10)">编辑</div>
										<div class="expectDel" @click="expectDel(item)">删除</div>
									</div>
								</div>
								<div class="flex">
									<div class="expectLeft flex1">
										<!-- <div>{{ item.name }} | {{item.infoDes}}</div> -->
										<div>{{ item.infoDes }}</div>
									</div>
									<div class="expectRight flex1">
										<div class="expectSalary">
											{{ stringToDates(item.endTime) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 个人中心 -->
				<div class="center" v-if="navindex == 0">
					<div class="preInfo">
						<div class="portrait">
							<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
								:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
								:on-success="uploadLogoSuccess" :on-error="uploadimageerror">
								<img :src="setuserlogo(user.peR_IMG_PATH)" alt="" />
							</el-upload>
						</div>
						<div class="name" @click="navindex = 1" style="margin-top:20px ;">
							{{ user.peR_NAME ? user.peR_NAME : "神秘人" }}
						</div>
						<div class="infomation" style="margin-top:13px ;">
							<div class="flex2">
								<span>{{ user.peR_GENDER }}</span>
								<span>|</span>
								<span>{{
                          user.peR_WORK_YEARS
                            ? "工作" + user.peR_WORK_YEARS + "年"
                            : "年限未知"
                        }}</span>
								<span>|</span>
								<span>{{
                          user.peR_HEDU_NAME === null
                            ? "学历未知"
                            : user.peR_HEDU_NAME
                        }}</span>
							</div>
							<div class="flex2 wz" style="margin-top:32px ;">
								<span>简历完整度</span>
								<b>{{user.integrity}}%</b>
							</div>
							<div class="flex2" style="margin-top:10px ;">
								<label>
									<span :style="{width: user.integrity+'%'}"></span>
								</label>
							</div>
							<div class="flex2" style="margin-top:10px ;">
								<span>简历完整度50%,才允许投递</span>
							</div>
							<div class="flex" style="margin-top:20px ;">
								<el-select style="width: 260px" v-model="newuser.PER_PER_STATUS" placeholder="求职状态"
									@change="saveresume">
									<el-option v-for="item in Statuslist" :key="item" :label="item" :value="item">
									</el-option>
								</el-select>
							</div>
							<div class="flex" style="margin-top:20px ;">
								<a @click="uid=user.peR_ID; isRshow=!isRshow" class="btn"
									style="width: 100%;color: #fff;background-color: #46A1F2;">预览简历</a>
							</div>
						</div>
					</div>
				</div>
				<!-- 已投递 -->
				<div v-if="navindex === 2">
					<div class="mainTitle">
						<div class="title">投递岗位</div>
					</div>
					<!-- <div class="recommend">
						<div class="recTab flex1">
							<div :class="['recTabItem', ISGWClick ? 'itemClick' : '']" @click="ISClickChange()">
								普通岗位
							</div>
							<div :class="['recTabItem', ISGWClick ? '' : 'itemClick']" @click="ISClickChange()">
								招聘会岗位
							</div>
						</div>
					</div> -->
					<div class="delivery">
						<div class="Dlist flex" v-for="item in DlistList" :key="item.id"
							@click="item.oF_STATUS != 1 ? goto('/jobdetail', { id: item.oF_ID }) : ''">
							<div class="DlistLeft">
								<div class="flex1">
									<div class="DlistName">{{ item.oF_POSI_NAME }}</div>
									<div class="DlistDate">
										{{ stringToDates(item.oF_REDATE) }}
									</div>
								</div>
								<div class="flex1" style="margin: 15px 0">
									<div class="DlistSalary">
										{{item.oF_SALARY_MY == 1? "面议" : setsalary(item.oF_SALARY, item.oF_SALARYE)}}
									</div>
									<div class="flex1">
										<div class="DlistFactor">
											{{ setDlistFactor(item) }}
										</div>
									</div>
								</div>
								<div class="flex1">
									<div class="DlistWelfare" v-if="item.oF_FLZL_QT != null && item.oF_FLZL_QT != ''">
										{{ item.oF_FLZL_QT }}
									</div>
								</div>
							</div>
							<div class="DlistRight">
								<div class="DlistCo">{{ item.com_name }}</div>
								<div class="DlistHot">
									<b>{{ item.counts }}</b>个热招职位
								</div>
								<div class="DlistBtn">已投递</div>
							</div>
						</div>
						<div class="Dlist flex" v-if="DlistList.length === 0">
							暂无已投递信息
						</div>
						<!-- 分页 -->
						<div class="page" v-if="joblistfeom.pageSize < total">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								hide-on-single-page :current-page="joblistfeom.pageNumber"
								:page-sizes="[10, 20, 30, 40]" :page-size="joblistfeom.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 已收藏 -->
				<div v-if="navindex === 3">
					<div class="mainTitle">
						<div class="title">收藏岗位</div>
					</div>
					<div class="delivery">
						<div class="Dlist flex" v-for="item in DlistList" :key="item.id"
							@click="item.oF_STATUS!=1?goto('/jobdetail', {id: item.oF_ID}):''">
							<div class="DlistLeft">
								<div class="flex1">
									<div class="DlistName">
										{{ item.oF_POSI_NAME }}
									</div>
									<div class="DlistDate">
										{{ stringToDates(item.oF_REDATE) }}
										<span class="red">{{item.oF_STATUS == 1 ? "已停止招聘" : ""}}</span>
									</div>
								</div>
								<div class="flex1" style="margin: 15px 0">
									<div class="DlistSalary">
										{{item.oF_SALARY_MY == 1? "面议" : setsalary(item.oF_SALARY, item.oF_SALARYE)}}
									</div>
									<div class="flex2">
										<div class="DlistFactor">
											{{ setDlistFactor(item) }}
										</div>
										<div class="DlistFactor">
											{{ item.leixing }}
											{{ item.hangye }}
											{{ item.leixing }}
										</div>
									</div>
								</div>
								<div class="flex1">
									<div class="DlistWelfare" v-if="item.oF_FLZL_QT != null && item.oF_FLZL_QT != ''">
										{{ item.oF_FLZL_QT }}
									</div>
								</div>
							</div>
							<div class="DlistRight">
								<div class="DlistCo">{{ item.com_name }}</div>
								<div class="DlistHot">
									<b>{{ item.counts }}</b>个热招职位
								</div>
								<div class="DlistBtn">已收藏</div>
							</div>
						</div>
						<div class="Dlist flex" v-if="DlistList.length === 0">
							暂无已收藏信息
						</div>
						<!-- 分页 -->
						<div class="page" v-if="joblistfeom.pageSize < total">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								hide-on-single-page :current-page="joblistfeom.pageNumber"
								:page-sizes="[10, 20, 30, 40]" :page-size="joblistfeom.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 谁看过我 v-if="navindex == 4" -->
				<div v-if="false">
					<div class="mainTitle">
						<div class="title">谁看过我</div>
					</div>
					<div class="browse" v-for="(browseItem, browse) in browseList" :key="browse">
						<div class="flex1">
							<div class="browsePic"><img :src="browseItem.pic" alt="" /></div>
							<div class="browseName">{{ browseItem.name }}</div>
						</div>
						<div class="browseBox flex2">
							<div class="browseBoxLeft">
								<div class="flex1" style="margin-bottom: 15px">
									<div class="browsePost">{{ browseItem.post }}</div>
									<div class="browseDate">{{ browseItem.date }}</div>
								</div>
								<div class="flex1">
									<div class="browseSalary">{{ browseItem.salary }}</div>
									<div class="browseFactor"
										v-for="(browseFactor, factor) in browseItem.browseFactorList" :key="factor">
										{{ browseFactor.factor }}
									</div>
								</div>
							</div>
							<div class="browseBoxRight">
								<div class="browseCo">{{ browseItem.co }}</div>
								<div class="flex">
									<div class="coFactor" v-for="(coFactorItem, coFactor) in browseItem.coFactorList"
										:key="coFactor">
										{{ coFactorItem.coFactor }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 分页 -->
					<div class="page">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage6" :page-sizes="[100, 200, 300, 400]" :page-size="100"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>

				<!-- 我的课程 -->
				<div v-if="navindex == 5">
					<div class="mainTitle">
						<div class="title">我的课程</div>
					</div>
					<div class="saveBox">
						<div class="project">
							<div class="trainingProgram" v-for="item in orderlist" :key="item.id">
								<div class="trainingProgram_title"
									@click="goto('/trainingDetails', {id: item.course_id}, 1)">
									<el-image fit="fill" :src="defimgurl+item.imgPic"></el-image>
								</div>
								<div class="trainingProgram_center">
									<div class="ttitle">{{item.course_name}}</div>
									<div
										style="display: flex;justify-content: space-around;padding-top: 23px;align-items: center;">
										<div>
											<!-- <div style="font-size: 12px;color: #666;padding-bottom: 8px;">初级·102人报名
											</div> -->
											<div
												style="font-size: 14px;color: #F90E0E;font-family: SourceHanSansSC-regular;font-weight: bold;">
												￥{{item.iMoney}}</div>
										</div>
										<div class="signup" v-if="item.orderState==1" @click="payment(1,item.order_no)">
											待确认</div>
										<div class="signup" v-if="item.orderState==2" @click="payment(2,item.order_no)">
											未支付</div>
										<div class="signup" v-if="item.orderState==3">已报名</div>
									</div>
								</div>
							</div>
						</div>
						<div class="Dlist flex" v-if="orderlist.length === 0">
							暂无报名信息
						</div>
						<!-- 分页 -->
						<div class="page" v-if="joblistfeom.pageSize < total">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								hide-on-single-page :current-page="joblistfeom.pageNumber"
								:page-sizes="[10, 20, 30, 40]" :page-size="joblistfeom.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 招考报名 -->
				<div v-if="navindex == 7">
					<div class="mainTitle">
						<div class="title">招考报名</div>
					</div>
					<div class="saveBox">
						<el-table :data="orderlist" border>
							<el-table-column label="序号" type="index" width="60" align="center">
							</el-table-column>
							<el-table-column label="单位" prop="examCompanyId" width="300">
							</el-table-column>
							<el-table-column label="岗位" prop="examItemName"></el-table-column>
							<el-table-column label="报名时间" prop="createTime" width="120">
								<template slot-scope="scope">
									{{utils.stringToDate(scope.row.createTime)}}
								</template>
							</el-table-column>
							<el-table-column label="状态" prop="auditState" width="100" align="center">
								<template slot-scope="scope">
									<div class="signup" v-if="scope.row.auditState==0">
										待审核</div>
									<!--<div class="signup" v-if="scope.row.state==1">
										初审已通过</div>-->
									<div class="signup" v-if="scope.row.auditState==99">
										已取消</div>
									<div class="signup" v-if="scope.row.auditState==2">
										已驳回</div>
									<div class="signup blue" v-if="scope.row.auditState==1" @click="payment(3,scope.row.payNo)">
										待支付</div>
									<div class="signup" v-if="scope.row.auditState==5">
										等待生成准考证</div>
									<div class="signup blue" v-if="scope.row.auditState==6"
										@click="goto('Ticket',{id:scope.row.id},2)">
										打印准考证</div>
									<div class="signup" v-if="scope.row.auditState==7">
										已生成准考证</div>
								</template>
							</el-table-column>
							<el-table-column label="审核说明" prop="auditMome"></el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<a class="blue" @click="goto('signup',{id:scope.row.id,status:scope.row.auditState},2)">查看</a>
								</template>
							</el-table-column>
						</el-table>
						<div class="Dlist flex" v-if="orderlist.length === 0">
							暂无报名信息
						</div>
						<!-- 分页 -->
						<div class="page" v-if="joblistfeom.pageSize < total">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								hide-on-single-page :current-page="joblistfeom.pageNumber"
								:page-sizes="[10, 20, 30, 40]" :page-size="joblistfeom.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 职场干货 -->
				<div v-if="navindex == 8">
					<!-- 副导航 -->
					<div class="subNav flex1">
						<div v-for="item in subNavList" :key="item.id" @click="subNavChange(item.id)"
							:class="['subNavItem',item.id == subNavIndex ? 'subNavItemClick' : '',]">
							{{ item.name }}
						</div>
					</div>
					<div class="course_mian">
						<div class="course" v-for="item in courseList" :key="item.id">
							<div class="flex4">
								<div class="courseBanner">
									<img :src="setcompanylogo(item.cIMG)" alt="" />
								</div>
								<div class="courseRight">
									<div class="courseTitle">{{ item.cTITLE }}</div>
									<div v-html="item.cDesc"></div>
									<div class="courseDate">
										{{ stringToDates(item.dREDATE) }}
									</div>
								</div>
							</div>
						</div>
						<!-- 分页 -->
						<div class="page" v-if="joblistfeom.pageSize < total">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page="joblistfeom.pageNumber" hide-on-single-page
								:page-sizes="[10, 20, 30, 40]" :page-size="joblistfeom.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 安全中心 -->
				<div v-if="navindex == 4">
					<div class="mainTitle">
						<div class="title">安全中心</div>
					</div>
					<div class="saveBox">
						<div class="saveLine flex1">
							<div class="saveIcon">
								<img src="../../assets/img/mmdl.png" alt="" />
							</div>
							<div class="saveTitle">密码登录</div>
							<div class="saveCont already">
								互联网账号存在被盗风险，建议您定期更改密码以保护账号安全
							</div>
							<div class="saveEdit" @click="openwin(7, '')">修改</div>
						</div>
						<div class="saveLine flex1">
							<div class="saveIcon">
								<img src="../../assets/img/sjtb.png" alt="" />
							</div>
							<div class="saveTitle">手机</div>
							<div class="saveCont already">
								{{ midphone(user.peR_CELLPHONE)}}<span v-if="user.peR_CELLPHONE != null">（已绑定）</span>
							</div>
							<div class="saveEdit" v-if="false" @click="TELchange = true">
								修改
							</div>
						</div>
						<!-- <div class="saveLine flex1">
							<div class="saveIcon">
								<img src="../../assets/img/yxtb.png" alt="" />
							</div>
							<div class="saveTitle">邮箱</div>
							<div class="saveCont">{{ user.peR_EMAIL }}</div>
							<div class="saveEdit" @click="openwin(8, '')">修改</div>
						</div> -->

						<div class="saveBTN" @click="loingout">退出账户</div>
					</div>
				</div>
			</div>
		</div>
		<resumedetail :isRshow="isRshow" :isCall="1" :uid="uid"></resumedetail>
		<!-- 抽屉 -->
		<!-- 编辑自我评价 -->
		<base-drawer :visible.sync="drawer" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑自我评价</div>
			</div>
			<el-form style="margin-top: 15px;">
				<el-form-item label="自我评价:">
					<el-input style="width: 80%;" rows="6" type="textarea" v-model="from1.field"
						placeholder="请输入您的自我评价"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom1">提交</el-button>
		</base-drawer>
		<!-- 编辑求职期望 -->
		<base-drawer :visible.sync="drawer1" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑求职期望</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="岗位名称" :required="true">
					<el-input v-model="work.name" type="text"></el-input>
				</el-form-item>
				<el-form-item label="期望薪资:" :required="true">
					<div class="flex">
						<el-input v-model="work.salary" type="number" placeholder="初始薪资">
						</el-input>
						<div style="margin: 0 5px; color: #999">-</div>
						<el-input v-model="work.salarye" type="number" placeholder="结束薪资">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="期望城市" :required="true">
					<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="工作性质:" :required="true">
					<el-radio-group v-model="work.nature">
						<el-radio label="不限">不限</el-radio>
						<el-radio label="全职">全职</el-radio>
						<el-radio label="兼职">兼职</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="期望行业:" :required="true">
					<el-select style="width: 260px" v-model="work.hy" placeholder="行业">
						<el-option v-for="item in industrylist" :key="item.cCode" :label="item.cName"
							:value="item.cCode"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom2()">提交</el-button>
		</base-drawer>
		<!-- 编辑工作经历 -->
		<base-drawer :visible.sync="drawer2" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑工作经历</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="企业名称:" :required="true">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="工作时间:" :required="true">
					<div class="flex">
						<el-date-picker v-model="info.stateTime" type="date" placeholder="初始日期"
							value-format="yyyy-MM-dd" :picker-options="pickerOptionsBegin">
						</el-date-picker>
						<div style="margin: 0 5px; color: #999">-</div>
						<el-date-picker v-model="info.endTime" type="date" placeholder="结束日期" value-format="yyyy-MM-dd"
							:picker-options="pickerOptionsEnd">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="岗位:" :required="true">
					<el-input v-model="info.name"></el-input>
				</el-form-item>
				<el-form-item label="工作内容:" :rows="6" :required="true">
					<el-input type="textarea" v-model="info.infoDes"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom3">提交</el-button>
		</base-drawer>
		<!-- 编辑教育经历 -->
		<base-drawer :visible.sync="drawer3" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑教育经历</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="院校(机构):" :required="true">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="毕业时间:" :required="true">
					<div class="flex">
						<el-date-picker v-model="info.endTime" type="date" placeholder="毕业时间" value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="学历:" :required="true">
					<el-select style="width: 260px" v-model="info.name" placeholder="学历">
						<el-option v-for="item in educationlist" :key="item.cCode" :label="item.cName"
							:value="item.cCode"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="专业:" :required="true">
					<el-input v-model="info.infoDes"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom4">提交</el-button>
		</base-drawer>
		<!-- 编辑密码修改 -->
		<base-drawer :visible.sync="drawer4" :size="460">
			<div slot="header">
				<div class="drawer-head">密码修改</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="80px">
				<el-form-item label="旧密码:">
					<el-input v-model="from4.oldpassword" type="password"></el-input>
				</el-form-item>
				<el-form-item label="新密码:">
					<el-input v-model="from4.password" type="password"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码:">
					<el-input v-model="from4.repassword" type="password"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom5">提交</el-button>
		</base-drawer>
		<base-drawer :visible.sync="drawer5" :size="460">
			<div slot="header">
			</div>
			<el-form label-width="80px">
				<el-form-item label="邮箱:">
					<el-input v-model="from5.email" type="text"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom6">提交</el-button>
		</base-drawer>
		<!-- 编辑项目经历 -->
		<base-drawer :visible.sync="drawer6" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑项目经历</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="项目名称:" :required="true">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="项目时间:" :required="true">
					<div class="flex">
						<el-date-picker v-model="info.stateTime" type="date" placeholder="初始日期"
							value-format="yyyy-MM-dd" :picker-options="pickerOptionsBegin">
						</el-date-picker>
						<div style="margin: 0 5px; color: #999">-</div>
						<el-date-picker v-model="info.endTime" type="date" placeholder="结束日期" value-format="yyyy-MM-dd"
							:picker-options="pickerOptionsEnd">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="担任职位:" :required="true">
					<el-input v-model="info.name"></el-input>
				</el-form-item>
				<el-form-item label="项目内容:" :required="true">
					<el-input type="textarea" :rows="6" v-model="info.infoDes"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom6">提交</el-button>
		</base-drawer>
		<!-- 编辑个人信息 -->
		<base-drawer :visible.sync="drawer7" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑个人信息</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="姓名:" :required="true">
					<el-input v-model="newuser.PER_NAME" type="text"></el-input>
				</el-form-item>
				<el-form-item label="身份证:" :required="true">
					<el-input v-model="newuser.PER_IDCARD" type="text"></el-input>
				</el-form-item>
				<el-form-item label="性别:" :required="true">
					<el-radio-group v-model="newuser.PER_GENDER">
						<el-radio :label="'男'">男</el-radio>
						<el-radio :label="'女'">女</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="出生年月:" :required="true">
					<el-date-picker style="width: 260px" v-model="newuser.PER_BIRTHDAY" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="所在城市" :required="true">
					<el-cascader size="large" :options="options" v-model="selectedOptionsBaseInfo" @change="handleChangeBaseInfo">
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址:" :required="true">
					<el-input v-model="newuser.PER_ADDRESS" type="text"></el-input>
				</el-form-item>
				<el-form-item label="最高学历:" :required="true">
					<el-select style="width: 260px" v-model="newuser.PER_HEDU" placeholder="学历">
						<el-option v-for="item in educationlist" :key="item.cCode" :label="item.cName"
							:value="item.cCode"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="手机号码:" :required="true">
					<el-input v-model="newuser.PER_CELLPHONE" type="text"></el-input>
				</el-form-item>
				<el-form-item label="邮箱:">
					<el-input v-model="newuser.PER_EMAIL" type="text"></el-input>
				</el-form-item>
				<el-form-item label="工作年限:" :required="true">
					<el-input v-model="newuser.PER_WORK_YEARS" type="number"><i slot="suffix"
							style="font-style:normal;margin-right: 10px;">年</i></el-input>
				</el-form-item>
				<el-form-item label="求职状态:" :required="true">
					<el-select style="width: 260px" v-model="newuser.PER_PER_STATUS" placeholder="求职状态">
						<el-option v-for="item in Statuslist" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="saveresume((res) => {drawer7 = false;})">提交
			</el-button>
		</base-drawer>
		<!-- 编辑职业技能 -->
		<base-drawer :visible.sync="drawer8" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑职业技能</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="80px">
				<el-form-item label="职业技能:">
					<el-input style="width: 80%" rows="10" type="textarea" v-model="newuser.peR_JNTC_INTRODUCE"
						placeholder="请输入您的职业技能">
					</el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom7()">提交</el-button>
		</base-drawer>
		<!-- 编辑职称证书 -->
		<base-drawer :visible.sync="drawer9" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑职称证书</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="证书名称:" :required="true">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="获得时间:" :required="true">
					<div class="flex">
						<el-date-picker v-model="info.endTime" type="date" placeholder="获得时间" value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="证书编号:" :required="true">
					<el-input v-model="info.name" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" :rows="6" v-model="info.infoDes"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom9">提交</el-button>
		</base-drawer>
		<!-- 编辑简历附件 -->
		<base-drawer :visible.sync="drawer10" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑简历附件</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="100px">
				<el-form-item label="附件名称:" :required="true">
					<el-input v-model="info.title"></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-upload class="el-button el-button--default el-button--mini"
						:action="baseurl + '/Admin/Upload/uploadfile'" :show-file-list="false"
						:headers="headers" accept=".pdf" :on-success="uploadSuccess"
						:on-error="uploadError">
						<i class="el-icon-plus"></i><span>添加附件</span>
					</el-upload>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input v-model="info.infoDes"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="subfrom10">提交</el-button>
		</base-drawer>
		<el-dialog width="860px" title="兰溪人才网-消息" :visible.sync="Mdialog" :direction="direction" class="messagedetail">
			<div class="flex">
				<div class="message-left innerbox">
					<div class="message-list ">
						<div class="item flex" v-for="(item, index) in messageList" :key="index"
							@click="getmessageinfo(item)">
							<div class="mc">
								<a @click="gethidemessageinfo(item)"><i class="el-icon-delete red"></i></a>
							</div>
							<div class="Logo"><img :src="setuserlogo(item.Logo)" /></div>
							<div style="width: 75%;">
								<div class="flex2">
									<div class="name">
										<span>{{item.name}}</span>
									</div>
									<div class="time">
										{{item.time}}
									</div>
								</div>
								<div class="flex2">
									<div class="info">{{item.memu}}</div>
									<div class="badge" v-if="item.read>0">
										<div class="badge-i">{{item.read}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="message-right">
					<div class="message-box">
						<div class="message-title">
							{{dialogTitle}}
						</div>
						<div class="message-detail innerbox">
							<div class="message-scroll">
								<div v-for="(item, index) in messageInfo" :key="index">
									<!-- 岗位 -->
									<div class="postList" v-if="item.typeId == 1||item.typeId == 6"
										v-for="(items, indexs) in item.memu" :key="indexs">
										<div class="md-company">
											<div class="flex">
												<div class="flex1">
													<div class="name">
														{{item.typeId == 6?items.Zwmc?items.Zwmc:"": items.OF_POSI_NAME?items.OF_POSI_NAME:'' }}
													</div>
													<!-- <image src="https://www.zjykrc.com/img/ji.png" alt="" /> -->
												</div>
												<div class="salarr" v-if='item.typeId == 1'>
													{{item.OF_SALARY_MY == 1 || item.OF_SALARY_MY == null ? "面议" : item.SALARY}}
												</div>
												<div class="salarr" v-if='item.typeId == 6'>
													{{items.cMy == 1 || items.cMy == null? "面议": numK(items.cdyb,items.cdye)}}
												</div>
											</div>
											<!-- <div class="postInfo">{{ items.OF_POSI_DESC }}</div> -->
											<div class="flex1" v-if='item.typeId == 1'>
												<div class="factor" v-if='items.OF_POSI_NATURE'>
													{{ items.OF_POSI_NATURE }}
												</div>
												<div class="factor" v-if='items.OF_EDU_REQ'>
													{{items.OF_EDU_REQ == "不限"? "学历" + items.OF_EDU_REQ : items.OF_EDU_REQ}}
												</div>
												<div class="factor" v-if='items.OF_WORKAREA'>
													{{ items.OF_WORKAREA ? items.OF_WORKAREA : "城市未知" }}
												</div>
											</div>
											<div class="flex1" v-if='item.typeId == 6'>
											</div>
											<div class="flex2">
												<div :class="['portrait', 'qycolor' + items.Num]">
													{{ items.LogoName }}
												</div>
												<div class="tel">
													<div>{{ items.COM_NAME }}</div>
													<div>{{ items.COM_address }}</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 对话框 -->
									<div class="message-hr dialog" v-if="item.typeId == 0 && item.idType!=idType">
										<div :class="['message-hr-img', 'portrait', 'qycolor' + item.num]">
											<img :src="setuserlogo(item.Logo)" />
										</div>
										<div class="message-hr-news dialogCont">
											{{ item.memu }}
										</div>
									</div>
									<div class="message-me dialog" v-if="item.typeId == 0 && item.idType == idType">
										<div class="message-me-news dialogCont">
											{{ item.memu }}
										</div>
										<div :class="['message-me-img', 'portrait', 'qycolor' + item.num]">
											<img :src="setcompanylogo(item.Logo)" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--输入框-->
						<div class="writing flex">
							<input type="text" name="Memu" v-model="Memu" placeholder="请输入..." />
							<el-button class="mf-icon" @click="SendMemu()">发送</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		isnull,
		getuserinfo
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import {
		Getposilist,
		Getnewslist,
		getTrainingOrderList,
		getmessagelist,
		getmessageinfo,
		addmessageinfo,
		hidemessageinfo
	} from "@/api/home";
	import {
		opercompanyinfo,
		IsReadMessage,
	} from "@/api/company";
	import {
		Setoperzwpj,
		Getresumelist,
		Getfavlist,
		Createresumeinfo,
		EditPassWord,
		Editemail1,
		zphresumelist,
		getinfolist,
		addinfo,
		delinfo,
		getworklist,
		addwork,
		delwork,
		ExamSignUpList
	} from "@/api/user";
	import global from "@/assets/js/globalconfig";
	import {
		regionData,
		CodeToText
	} from "element-china-area-data";
	import resumedetail from "../resume/resumedetail.vue";
	export default {
		components: {
			resumedetail
		},
		data() {
			return {
				// 消息
				rid: 0,
				messageList: [],
				messageInfo: [],
				dialogTitle: "消息",
				pageScrollTo: true,
				Memu: '',
				Mdialog: false,
				direction: "rtl",
				userinfo: "",
				idType: 2,

				uid: 0,
				isRshow: false,
				ISGWClick: true,
				baseurl: global.baseurl,
				defimgurl: global.baseimgurl,
				user: {},
				newuser: {
					PER_ID: "", //用户ID
					PER_NAME: "", //姓名
					PER_IDCARD: "", //身份证
					PER_GENDER: "", //性别
					PER_BIRTHDAY: "", //出生日期
					PER_SHENFEN: "", //身份（职场人、在校生（中文））
					PER_STARTWORK: "", //开始工作年份
					PER_PPRO_CODE: "", //居住地省
					PER_PCT_CODE: "", //居住地市
					PER_PXT_CODE: "", //居住地区县
					PER_EMAIL: "", //邮箱
					per_tag: "", //个人标签
					PER_HOPE_SALARY: "", //期望薪资
					PER_JNTC_INTRODUCE: "", //技能
					PER_HOPE_INDUSTRY: "", //期望行业
					PER_GRADUATE_SCH: "", //毕业院校
					PER_EDU_NIAN: "", //毕业年
					PER_EDU_YUE: "", //毕业月
					PER_HEDU: "", //学历
					PER_MAJOR_NAME: "", //专业
					PER_NOW_POSITYPE: "", //当前所在岗位
					PER_NOW_INDUSTRY: "", //当前所在行业
					PER_WORK_INTRODUCE: "", //工作描述
					PER_ADDRESS: "", //现居住地
					PER_CELLPHONE: "", //手机号码
					PER_WORK_YEARS: "", //工作年限
					PER_APPLY_TYPE: "", //求职意向
					PER_PER_STATUS: "", //求职状态
				},
				Statuslist: ["当前在职", "考虑就业机会", "已经离职", "暂时保密"],
				activeName: "second",
				info: {
					id: 0,
					type: 0,
					title: "",
					name: "",
					stateTime: '',
					endTime: '',
					infoDes: '',
				},
				drawer: false,
				from1: {
					id: "",
					field: "",
				},
				drawer1: false, //求职意向
				work: {
					id: 0,
					name: "",
					nature: "全职",
					salary: "",
					salarye: "",
					city: "",
					hy: "",
				},
				worklist: [],
				options: regionData,
				selectedOptions: ["330000", "330700", "330781"],
				selectedOptionsBaseInfo: [],
				drawer2: false, //工作经历
				infolist1: [],
				drawer3: false, //教育经历
				infolist2: [],
				drawer6: false, //项目经历
				infolist3: [],
				drawer9: false, //职称证书
				infolist9: [],
				drawer10: false, //简历附件
				infolist10: [],
				from3: {
					name: "",
					jobname: "",
					content: "",
				},
				drawer4: false, //密码修改
				from4: {
					oldpassword: "", //旧密码
					password: "", //新密码
					repassword: "", //重复新密码
				},
				drawer5: false, //邮箱修改
				from5: {
					email: "", //邮箱
				},
				joblistfeom: {
					id: "",
					pageNumber: 1,
					pageSize: 10,
				},
				drawer7: false,
				drawer8: false,
				total: 0,
				industrylist: [],
				educationlist: [],
				checkedA: false,
				checkedB: false,
				TELchange: false,
				auth: false,
				subNavIndex: "1018",
				currentPage4: 1,
				currentPage5: 1,
				currentPage6: 1,
				currentPage7: 1,
				radio: "1",
				editBtn: true,
				btn: false,
				navindexHover: "",
				navindex: 1,
				ISClick: true,
				courseList: [],
				browseList: [],
				nav: [{
						index: 0,
						name: "个人中心",
						icon: require("@/assets/img/c3.png"),
						iconc: require("@/assets/img/c4.png"),
					},
					// {
					// 	index: 1,
					// 	name: "简历编辑",
					// 	icon: require("@/assets/img/c24.png"),
					// 	iconc: require("@/assets/img/c25.png"),
					// },
					{
						index: 2,
						name: "已投递",
						icon: require("@/assets/img/c22.png"),
						iconc: require("@/assets/img/c23.png"),
					},
					{
						index: 3,
						name: "已收藏",
						icon: require("@/assets/img/c20.png"),
						iconc: require("@/assets/img/c21.png"),
					},
					{
						index: 5,
						name: "我的课程",
						icon: require("@/assets/img/company/nav6.png"),
						iconc: require("@/assets/img/company/nav6h.png"),
					},
					{
						index: 7,
						name: "招考报名",
						icon: require("@/assets/img/company/nav7.png"),
						iconc: require("@/assets/img/company/nav7h.png"),
					},
					{
						index: 4,
						name: "安全中心",
						icon: require("@/assets/img/c1.png"),
						iconc: require("@/assets/img/c2.png"),
					},
				],
				itemList: [{
						subTitle: "基本信息",
						number: "2项",
					},
					{
						subTitle: "求职意向",
						number: "2项",
					},
				],
				privacyList: [{
						left: "谁能查看我的简历",
						right: "企业HR、猎头",
						url: "",
					},
					{
						left: "屏蔽公司",
						right: "已屏蔽2个",
						url: "",
					},
				],
				DlistList: [],
				subNavList: [{
						id: "1018",
						name: "简历制作",
					},
					{
						id: "1019",
						name: "面试指南",
					},
					{
						id: "1020",
						name: "求职锦囊",
					},
					{
						id: "1021",
						name: "薪资行情",
					},
					{
						id: "1022",
						name: "职业指南",
					},
				],
				comfrom: {
					COM_ID: "", //公司ID
					COM_NAME: "", //公司名
					COM_LINKMAN: "", //联系人
					COM_ZJLB: "", //证件类别
					COM_ZJLB_CODE: "", //证件编号
					COM_MPHONE: "", //手机号码
					COM_PHONE: "", //联系电话
					COM_ZIPCODE: "", //邮编
					COM_NATURE: "", //公司性质
					COM_SCALE: "", //公司规模
					COM_WEBSITE: "", //公司网址
					COM_INDUSTRY: "", //所属行业
					COM_EMAIL: "", //公司邮箱
					COM_HYTYPE: "", //招聘大类
					COM_VOUCHER: "", //营业执照
					COM_INTRODUCT: "", //公司介绍
					COM_ADDRESS: "",
					coM_PWD: "", //密码
					coM_ACCOUNT: "", //账号
				},
				headers: {
					token: localStorage.getItem("token"),
				},
				messageCount: 0,
				pickerOptionsBegin: {
					disabledDate: (time) => {
						if (this.info.endTime) {
							return time.getTime() > new Date(this.info.endTime);
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: (time) => {
						if (this.info.stateTime) {
							return time.getTime() < new Date(this.info.stateTime);
						}
					}
				},
				orderlist: [],
			};
		},
		watch: {
			$route: {
				handler(val, oldval) {
					if (!isnull(val.query.type)) {
						this.navindex = parseInt(val.query.type);
					}
				},
				// 深度观察监听
				deep: true,
			},
		},
		created() {
			if (!isnull(this.$route.query.type)) {
				this.navindex = parseInt(this.$route.query.type);
			}
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				this.usertonewuser();
			}
			this.updatainfo();
			this.Getresumelists();
			this.Getposilists(1, "学历");
			this.Getposilists(2, "行业");
			this.IsReadMessage();
			this.infolist();
			this.navChange(this.navindex);
			if (this.newuser.PER_PPRO_CODE != null && this.newuser.PER_PCT_CODE != null && this.newuser.PER_PXT_CODE != null) {
				this.selectedOptionsBaseInfo = [this.newuser.PER_PPRO_CODE, this.newuser.PER_PCT_CODE, this.newuser.PER_PXT_CODE];
			} else {
				this.selectedOptionsBaseInfo = ["330000", "330700", "330781"];
			}
			// this.timers = setInterval(() => {
			// 	this.IsReadMessage();
			// 	if (this.Mdialog)
			// 		this.getmessagelist();
			// 	if (this.rid > 0)
			// 		this.getmessageroom();
			// }, 3000);
		},
		destroyed: function() {
			clearInterval(this.timers)
		},
		methods: {
			return2Br(str) {
				if (str)
					return str.replace(/\r?\n/g, "<br />");
				else
					return "";
			},
			getTrainingOrderList(leverid) {
				var _this = this;
				_this.joblistfeom.cid = _this.user.peR_ID;
				_this.joblistfeom.leverid = leverid;
				getTrainingOrderList(_this.joblistfeom).then((res) => {
					console.log(res)
					if (res.success) {
						_this.orderlist = res.data.rows;
						_this.total = res.data.total;
					}
				})
			},
			GetExamSignUpList() {
				var _this = this;
				_this.joblistfeom.cid = _this.user.peR_ID;
				ExamSignUpList(_this.joblistfeom).then((res) => {
					if (res.success) {
						_this.orderlist = res.data.rows;
						_this.total = res.data.total;
					}
				})
			},
			payment(v, orderno) {
				/*this.goto('/orderPayment', {
					orderno: orderno
				}, 2)*/
				if (v == 3) {
					this.goto('/paybm', {
						orderno: orderno
					}, 2)
				} else {
					this.goto('/orderPayment', {
						orderno: orderno
					}, 2)
				}
			},
			infolist() {
				this.getworklist();
				this.getinfolist(1);
				this.getinfolist(2);
				this.getinfolist(3);
				this.getinfolist(4);
				this.getinfolist(5);

			},

			Statuscommand(e) {
				this.newuser.PER_PER_STATUS = e;
				this.saveresume((res) => {});
			},

			//上传logo成功
			uploadLogoSuccess(res, file) {
				// this.$message.success("上传成功!");
				this.newuser.peR_IMG_PATH = res.data.obj.files;
				this.saveresume((res) => {});
			},
			uploadimageerror(res, file) {
				this.$message.error("上传失败!");
			},
			//消息
			IsReadMessage() {
				var _this = this;
				var par = {
					id: this.user.peR_ID,
					types: 1,
				};
				IsReadMessage(par).then((res) => {
					if (res.success) {
						_this.messageCount = res.data.count;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			gethidemessageinfo(data) {
				var _this = this;
				handleCofirm('确认删除该聊天记录吗？', 'warning', ).then(res => {
					var par = {
						idType: _this.idType,
						Id: data.id,
					};
					hidemessageinfo(par).then((res) => {
						if (res.success) {
							this.getmessagelist()
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			//
			getmessagelist() {
				var _this = this;
				var par = {
					types: _this.idType,
					id: _this.user.peR_ID,
				};
				getmessagelist(par).then((res) => {
					if (res.success) {
						_this.messageList = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getmessageroom() {
				var _this = this;
				var par = {
					types: _this.idType,
					id: _this.rid,
				};
				getmessageinfo(par).then((res) => {
					if (res.success) {
						for (var i = 0; i < res.data.length; i++) {
							if (
								res.data[i]["typeId"] == 1 &&
								res.data[i]["memu"] != "" ||
								res.data[i]["typeId"] == 6 &&
								res.data[i]["memu"] != ""
							) {
								res.data[i]["memu"] = JSON.parse(res.data[i]["memu"]);
							}
						}
						_this.messageInfo = res.data;
						if (_this.pageScrollTo || _this.messageInfo.length != res.data.length) {
							_this.pageScrollTo = false
							setTimeout(function() {

							}, 100);
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			}, //提交内容
			SendMemu: function() {
				let _self = this;
				if (_self.Memu.length == 0) {
					_self.$message.error("不能发送空白消息！");
					return;
				}
				var par = {
					Memu: _self.Memu,
					RoomId: _self.rid,
					idType: _self.idType,
					PersonId: _self.userinfo.coM_ID,
					TypeId: 0,
				};
				addmessageinfo(par).then((res) => {
					if (res.success) {
						_self.Memu = "";
						_self.getmessageroom();
					} else {
						_self.$message.error(res.msg);
						_self.Memu = "";
					}
				});
			},
			getmessageinfo(data) {
				var _this = this;
				_this.rid = data.id
				_this.dialogTitle = isnull(data.name) ? "消息" : data.name;
				_this.getmessageroom()
			},
			getinfolist(cid) {
				var _this = this;
				var par = {
					id: this.user.peR_ID,
					cid: cid,
				};
				getinfolist(par).then((res) => {
					if (res.success) {
						if (cid == 1)
							_this.infolist1 = res.data;
						if (cid == 2)
							_this.infolist2 = res.data;
						if (cid == 3)
							_this.infolist3 = res.data;
						if (cid == 4)
							_this.infolist9 = res.data;
						if (cid == 5)
							_this.infolist10 = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getworklist() {
				var _this = this;
				var par = {
					id: this.user.peR_ID,
				};
				getworklist(par).then((res) => {
					if (res.success) {
						_this.worklist = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			// 求职期望 地区选取
			handleChange(value) {
				this.work.percode = value[0];
				this.work.citycode = value[1];
				this.work.areacode = value[2];
			},

			// 编辑个人信息 居住所在地 地区选取
			handleChangeBaseInfo(value) {
				this.newuser.PER_PPRO_CODE = value[0];
				this.newuser.PER_PCT_CODE = value[1];
				this.newuser.PER_PXT_CODE = value[2];
			},
			CodeToText(value) {
				return CodeToText[value];
			},
			readINTRODUCE(data) {
				if (!isnull(data)) {
					return data.split(";");
				} else {
					return [];
				}
			},
			subfrom1() {
				var _this = this;
				if (isnull(_this.from1.field)) {
					_this.$message.error("请填写优势亮点");
					return false;
				}
				Setoperzwpj(_this.from1).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.updatainfo();
						_this.drawer = false;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			updatainfo() {
				getuserinfo((res) => {
					this.user = res;

					this.usertonewuser();
				});
			},
			//更新求职意向
			subfrom2() {
				var _this = this;
				if (isnull(_this.work.name)) {
					this.$message.error("请填写岗位名称");
					return false;
				}
				if (isnull(_this.work.salary)) {
					this.$message.error("请填写期望薪资起始值");
					return false;
				}
				if (isnull(_this.work.salarye)) {
					this.$message.error("请填写期望薪资结束值");
					return false;
				}
				if (isnull(_this.work.hy)) {
					this.$message.error("请填写期望行业");
					return false;
				}
				_this.work.per_id = _this.user.peR_ID;
				_this.work.percode = _this.selectedOptions[0];
				_this.work.citycode = _this.selectedOptions[1];
				_this.work.areacode = _this.selectedOptions[2];
				addwork(_this.work).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer1 = false;
						this.getworklist();
						
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//更新工作经历
			subfrom3() {
				var _this = this;
				if (isnull(_this.info.title)) {
					this.$message.error("请填写企业名称");
					return false;
				}
				if (isnull(_this.info.stateTime)) {
					this.$message.error("请填写开始工作时间");
					return false;
				}
				if (isnull(_this.info.endTime)) {
					this.$message.error("请填写结束工作时间");
					return false;
				}
				if(!utils.compareTime(_this.info.stateTime, _this.info.endTime)) {
					this.$message.error("请填写正确的起始和截止时间");
					return false;
				}
				if (isnull(_this.info.name)) {
					this.$message.error("请填写岗位");
					return false;
				}
				if (isnull(_this.info.infoDes)) {
					this.$message.error("请填写工作内容");
					return false;
				}
				_this.info.per_id = _this.user.peR_ID;
				addinfo(_this.info).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer2 = false;
						this.getinfolist(1);
						_this.info = {
							type: 0,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
				// var list = [];
				// if (!isnull(_this.newuser.PER_WORK_INTRODUCE)) {
				//   list = JSON.parse(_this.newuser.PER_WORK_INTRODUCE);
				// }
				//  list.push(_this.from3);
				//_this.newuser.PER_WORK_INTRODUCE = JSON.stringify(list);
				/*_this.saveresume((res) => {
					_this.drawer2 = false;
				});*/
			},
			// 教育经历
			subfrom4() {
				var _this = this;
				if (isnull(_this.info.title)) {
					this.$message.error("请填写院校(机构)");
					return false;
				}
				if (isnull(_this.info.endTime)) {
					this.$message.error("请填写毕业时间");
					return false;
				}
				if (isnull(_this.info.name)) {
					this.$message.error("请选择学历");
					return false;
				}
				if (isnull(_this.info.infoDes)) {
					this.$message.error("请填写专业");
					return false;
				}
				_this.info.per_id = _this.user.peR_ID;
				_this.info.stateTime = _this.info.endTime;
				addinfo(_this.info).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer3 = false;
						this.getinfolist(2);
						_this.info = {
							type: 0,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
				/*this.saveresume((res) => {
					this.drawer3 = false;
				});*/
			},
			subfrom6() {
				var _this = this;
				if (isnull(_this.info.title)) {
					this.$message.error("请填写项目名称");
					return false;
				}
				if (isnull(_this.info.stateTime)) {
					this.$message.error("请填写项目开始时间");
					return false;
				}
				if (isnull(_this.info.endTime)) {
					this.$message.error("请填写项目结束时间");
					return false;
				}
				if(!utils.compareTime(_this.info.stateTime, _this.info.endTime)) {
					this.$message.error("请填写正确的起始和截止时间");
					return false;
				}
				if (isnull(_this.info.name)) {
					this.$message.error("请填写担任职位");
					return false;
				}
				if (isnull(_this.info.infoDes)) {
					this.$message.error("请填写项目内容");
					return false;
				}
				_this.info.per_id = _this.user.peR_ID;
				addinfo(_this.info).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer6 = false;
						this.getinfolist(3);
						_this.info = {
							type: 0,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			// 职称证书
			subfrom9() {
				var _this = this;
				if (isnull(_this.info.title)) {
					this.$message.error("请填写证书名称");
					return false;
				}
				if (isnull(_this.info.endTime)) {
					this.$message.error("请填写证书获得时间");
					return false;
				}
				if (isnull(_this.info.name)) {
					this.$message.error("请填写证书编号");
					return false;
				}
				_this.info.per_id = _this.user.peR_ID;
				_this.info.stateTime = _this.info.endTime;
				addinfo(_this.info).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer9 = false;
						this.getinfolist(4);
						_this.info = {
							type: 0,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			// 简历附件
			subfrom10() {
				var _this = this;
				if (isnull(_this.info.title)) {
					this.$message.error("请选择附件");
					return false;
				}
				_this.info.per_id = _this.user.peR_ID;
				var date = new Date();
				_this.info.stateTime = utils.timeFormat(date, 0);
				_this.info.endTime = utils.timeFormat(date, 0);
				addinfo(_this.info).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.drawer10 = false;
						this.getinfolist(5);
						_this.info = {
							type: 0,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			// 文件上传相关操作开始
			
			uploadSuccess(res, file) {
				this.$message.success("上传成功!");
				if (res.success) {
					var _this = this;
					if (res.data.code > 0) {
						_this.info.title = res.data.obj.title;
						_this.info.name = res.data.obj.files;
					} else {
						this.$message.error(res.data.msg);
					}
				}
				//this.form.avatar = res.data.obj.files;
			},
			uploadError(res, file) {
				this.$message.error("上传失败!");
			},

			// 文件上传相关操作结束

			subfrom7() {
				var _this = this;
				this.saveresume((res) => {
					_this.drawer8 = false;
				});
			},
			expectEdit(data, t) {
				var _this = this;
				if (t === 4) {
					_this.work = data;
					_this.selectedOptions = [data.percode, data.citycode, data.areacode],
						_this.drawer1 = true;
				} else {
					_this.info = data;
					if (t === 1) _this.drawer2 = true;
					if (t === 2) _this.drawer3 = true;
					if (t === 3) _this.drawer6 = true;
					if (t === 9) _this.drawer9 = true; // 职称证书
					if (t === 10) _this.drawer10 = true; // 简历附件
				}
			},
			expectDel(data) {
				var _this = this;
				_this
					.$confirm("确定删除" + data.title + "吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						_this.info.id = data.id;
						delinfo(_this.info).then((res) => {
							_this.$message({
								message: "操作成功",
								type: "success",
							});
							this.infolist();
						});
					})
			},
			expectEork(data) {
				var _this = this;
				_this
					.$confirm("确定删除" + data.name + "吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						_this.work.id = data.id;
						delwork(_this.work).then((res) => {
							_this.$message({
								message: "操作成功",
								type: "success",
							});
							this.infolist();
						});
					})
			},
			openwin(type, data) {
				var _this = this;
				switch (type) {
					case 1: //优势亮点编辑
						_this.from1 = {
							id: _this.user.peR_ID,
							field: _this.user.peR_INTRODUCE,
						};
						_this.drawer = true;
						break;
					case 2: //切换列表(我的投递/我的收藏)
						_this.joblistfeom = {
							id: "",
							pageNumber: 1,
							pageSize: 10,
						};
						if (data) {
							_this.Getresumelists();
						} else {
							_this.Getfavlists();
						}
						_this.ISClick = data;
						break;
					case 3: //查看更多(我的投递/我的收藏)
						_this.navindex = data ? 2 : 3;
						_this.joblistfeom = {
							id: "",
							pageNumber: 1,
							pageSize: 10,
						};
						if (data) {
							_this.Getresumelists();
						} else {
							_this.Getfavlists();
						}
						break;
					case 4: //新增求职意向
						_this.work = {
							id: 0,
							name: "",
							nature: "全职",
							salary: "",
							salarye: "",
							city: "",
							hy: "",
						};
						_this.selectedOptions = ["330000", "330700", "330781"];
						_this.drawer1 = true;
						break;
					case 5: //工作经历
						_this.info = {
							id: 0,
							type: 1,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
						// _this.from3 = {
						// 	name: "",
						// 	jobname: "",
						// 	content: "",
						// };
						_this.drawer2 = true;
						break;
					case 6: //教育经历
						_this.info = {
							id: 0,
							type: 2,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
						_this.drawer3 = true;
						break;
					case 7: //修改密码
						_this.from4 = {
							oldpassword: "", //旧密码
							password: "", //新密码
							repassword: "", //重复新密码
						};
						_this.drawer4 = true;
						break;
					case 8: //邮箱
						_this.drawer5 = true;
						break;
					case 9: //项目
						_this.info = {
							id: 0,
							type: 3,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
						_this.drawer6 = true;
						break;
					case 10:
						_this.drawer7 = true;
						break;
					case 11:
						_this.drawer8 = true;
						break;
					case 12: // 职称证书
						_this.info = {
							id: 0,
							type: 4,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
						_this.drawer9 = true;
						break;
					case 13: // 简历证书
						_this.info = {
							id: 0,
							type: 5,
							title: "",
							name: "",
							stateTime: '',
							endTime: '',
							infoDes: '',
						};
						_this.drawer10 = true;
						break;	
					default:
						break;
				}
			},
			//号码脱敏
			midphone(data) {
				if (isnull(data)) {
					return "";
				}
				return utils.phone(data);
			},
			//获取学历
			geteducationlist(data) {
				if (isnull(data)) {
					return "";
				}
				for (let i = 0; i < this.educationlist.length; i++) {
					if (this.educationlist[i].cCode == data)
						return this.educationlist[i].cName
				}
			},
			//年龄计算
			calculationage(data) {
				if (isnull(data)) {
					return false;
				}
				let birthdays = new Date(data.replace(/-/g, "/"));
				let d = new Date();
				let age =
					d.getFullYear() -
					birthdays.getFullYear() -
					(d.getMonth() < birthdays.getMonth() ||
						(d.getMonth() == birthdays.getMonth() &&
							d.getDate() < birthdays.getDate()) ?
						1 :
						0);
				return age;
			},
			ISClickChange() {
				this.ISGWClick = !this.ISGWClick;
				this.joblistfeom.pageNumber = 1;
				this.DlistList = [];
				if (this.ISGWClick) {
					this.Getresumelists();
				} else {
					this.zphresumelist();
				}
			},
			//我的投递
			Getresumelists() {
				var _this = this;
				_this.joblistfeom.id = _this.user.peR_ID;
				Getresumelist(_this.joblistfeom).then((res) => {
					if (res.success) {
						_this.DlistList = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//我投递的招聘会岗位
			zphresumelist() {
				var _this = this;
				_this.joblistfeom.id = _this.user.peR_ID;
				zphresumelist(_this.joblistfeom).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.oF_POSI_NAME = element.zwmc;
							element.oF_REDATE = element.csenddate;
							element.oF_SALARY = element.cDyb;
							element.oF_SALARYE = element.cDye;
							element.oF_SALARY_MY = element.cMy;
						});
						_this.DlistList = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//我的收藏
			Getfavlists() {
				var _this = this;
				_this.joblistfeom.id = _this.user.peR_ID;
				Getfavlist(_this.joblistfeom).then((res) => {
					if (res.success) {
						_this.DlistList = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setDlistFactor(datas) {
				var data = [];
				if (datas.oF_WORKAREA) {
					data.push(this.setdefval(datas.oF_WORKAREA));
				}
				if (datas.oF_GZJY) {
					data.push(this.setdefval(datas.oF_GZJY));
				}
				if (datas.oF_EDU_REQ) {
					data.push(this.setdefval(datas.oF_EDU_REQ));
				}
				if (datas.oF_POSI_NATURE) {
					data.push(this.setdefval(datas.oF_POSI_NATURE));
				}
				if (datas.cXl) {
					data.push(datas.cXl != "不限" ? datas.cXl : "学历不限");
				}
				if (datas.cHy) {
					data.push(datas.cHy != "不限" ? datas.cHy : "专业不限");
				}
				if (datas.cJZ) {
					data.push(datas.cHy == "0" || datas.cHy == 0 ? "" : "紧缺");
				}
				return data.join(" | ");
			},
			goto(url, data) {
				this.resolvewin(url, data, 2);
			},
			setdefval(data) {
				if (isnull(data)) {
					return "不限";
				} else {
					return data;
				}
			},
			//获取资讯信息
			Getnewslists(id) {
				var _this = this;
				var par = {
					types: id,
					pageSize: _this.joblistfeom.pageSize,
					pageNumber: _this.joblistfeom.pageNumber,
					showLoadType: ".course_mian",
				};
				Getnewslist(par).then((res) => {
					if (res.success) {
						_this.courseList = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Getposilists(type, datas) {
				var _this = this;
				Getposilist({
					types: datas
				}).then((res) => {
					if (res.success) {
						switch (type) {
							case 1: //学历
								res.data.rows.forEach((element) => {
									if (element.cName != "不限") {
										_this.educationlist = _this.educationlist.concat(element);
									}
								});
								break;
							case 2: //行业
								_this.industrylist = res.data.rows;
								break;
							default:
								break;
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			navChange(i) {
				this.navindex = parseInt(i);
				this.joblistfeom = {
					id: "",
					pageNumber: 1,
					pageSize: 10,
				};
				switch (i) {
					case 0:
						this.Getresumelists();
						break;
					case 2:
						this.Getresumelists();
						break;
					case 3:
						this.Getfavlists();
						break;
					case 4:
						this.Getnewslists(this.subNavIndex);
						break;
					case 5:
						this.getTrainingOrderList(1);
						break;
					case 7:
						this.GetExamSignUpList();
						break;
					default:
						break;
				}
			},
			//编辑简历
			saveresume(rok) {
				var _this = this;
				if (isnull(_this.newuser.PER_NAME)) {
					this.$message.error("请填写姓名");
					return false;
				}
				if (_this.newuser.PER_IDCARD.length != 18 && _this.newuser.PER_IDCARD.length != 15) {
					this.$message.error("您输入的身份证有误");
					return false;
				}
				if (isnull(_this.newuser.PER_GENDER)) {
					this.$message.error("请填写性别");
					return false;
				}
				if (isnull(_this.newuser.PER_BIRTHDAY)) {
					this.$message.error("请填写出生日期");
					return false;
				}
				if (isnull(_this.newuser.PER_ADDRESS)) {
					this.$message.error("请填写详细地址");
					return false;
				}
				if (isnull(_this.newuser.PER_HEDU)) {
					this.$message.error("请填写最高学历");
					return false;
				}
				// 校验手机号码
				var verify_phone_res = utils.telVerify(_this.newuser.PER_CELLPHONE);
				if (!verify_phone_res.state) {
					this.$message.error(verify_phone_res.message);
					return false;
				}
				if (isnull(_this.newuser.PER_WORK_YEARS)) {
					this.$message.error("请填写工作年限");
					return false;
				}
				if (isnull(_this.newuser.PER_PER_STATUS)) {
					this.$message.error("请填写求职状态");
					return false;
				}
				Createresumeinfo(_this.newuser).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.updatainfo();
						rok(true);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			usertonewuser() {
				var data = this.user;
				if (data.peR_ID == null) {
					this.resolvewin("/login", {
						type: 1
					}, 1);
				}
				this.newuser = {
					PER_ID: data.peR_ID, //用户ID
					PER_NAME: data.peR_NAME, //姓名
					PER_IDCARD: data.peR_IDCARD, //身份证
					PER_GENDER: data.peR_GENDER, //性别
					PER_BIRTHDAY: data.peR_BIRTHDAY, //出生日期
					PER_SHENFEN: data.peR_SHENFEN, //身份（职场人、在校生（中文））
					PER_STARTWORK: data.peR_STARTWORK, //开始工作年份
					PER_PPRO_CODE: data.peR_PPRO_CODE, //居住地省
					PER_PCT_CODE: data.peR_PCT_CODE, //居住地市
					PER_PXT_CODE: data.peR_PXT_CODE, //居住地区县
					PER_EMAIL: data.peR_EMAIL, //邮箱
					per_tag: data.per_tag, //个人标签
					PER_HOPE_SALARY: data.peR_HOPE_SALARY, //期望薪资
					PER_JNTC_INTRODUCE: data.peR_JNTC_INTRODUCE, //技能
					PER_HOPE_INDUSTRY: data.peR_HOPE_INDUSTRY, //期望行业
					PER_GRADUATE_SCH: data.peR_GRADUATE_SCH, //毕业院校
					PER_EDU_NIAN: data.peR_EDU_NIAN, //毕业年
					PER_EDU_YUE: data.peR_EDU_YUE, //毕业月
					PER_HEDU: data.peR_HEDU, //学历
					PER_MAJOR_NAME: data.peR_MAJOR_NAME, //专业
					PER_NOW_POSITYPE: data.peR_NOW_POSITYPE, //当前所在岗位
					PER_NOW_INDUSTRY: data.peR_NOW_INDUSTRY, //当前所在行业
					PER_WORK_INTRODUCE: data.peR_WORK_INTRODUCE, //工作描述

					PER_ADDRESS: data.peR_ADDRESS, //现居住地
					PER_CELLPHONE: data.peR_CELLPHONE, //手机号码
					PER_WORK_YEARS: data.peR_WORK_YEARS, //工作年限
					PER_APPLY_TYPE: data.peR_APPLY_TYPE, //求职意向
					PER_PER_STATUS: data.peR_PER_STATUS, //求职状态
				};
			},
			complete: function() {
				this.navindex = 1;
			},
			edit: function() {
				this.btn = true;
				this.editBtn = false;
			},
			cancel: function() {
				this.editBtn = true;
				this.btn = false;
			},
			handleSizeChange(val) {
				this.joblistfeom.pageNumber = 1;
				this.joblistfeom.pageSize = val;
				switch (this.navindex) {
					case 2:
						if (this.ISGWClick) {
							this.Getresumelists();
						} else {
							this.zphresumelist();
						}
						break;
					case 3:
						this.Getfavlists();
						break;
					case 4:
						this.Getnewslists(this.subNavIndex);
						break;
					case 5:
						this.getTrainingOrderList(1);
						break;
					case 7:
						this.GetExamSignUpList();
						break;
					default:
						break;
				}
			},
			handleCurrentChange(val) {
				this.joblistfeom.pageNumber = val;
				switch (this.navindex) {
					case 2:
						if (this.ISGWClick) {
							this.Getresumelists();
						} else {
							this.zphresumelist();
						}
						break;
					case 3:
						this.Getfavlists();
						break;
					case 4:
						this.Getnewslists(this.subNavIndex);
						break;
					case 5:
						this.getTrainingOrderList(1);
						break;
					case 7:
						this.GetExamSignUpList();
						break;
					default:
						break;
				}
			},
			subNavChange(i) {
				this.subNavIndex = i;

				this.Getnewslists(i);
			},
			stringToDates(data) {
				if (isnull(data)) {
					return "";
				}
				return utils.stringToDate(data);
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/neiye.jpg") :
					this.defimgurl + url;
			},

			setuserlogo(url) {
				var data = isnull(url) ? 0 : url.indexOf("http");
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
			subfrom5() {
				var _this = this;
				if (isnull(_this.from4.oldpassword)) {
					_this.$message.error("请输入旧密码");
					return false;
				}
				if (isnull(_this.from4.password)) {
					_this.$message.error("请输入新密码");
					return false;
				}
				if (isnull(_this.from4.repassword)) {
					_this.$message.error("请输入确认新密码");
					return false;
				}
				if (_this.from4.password != _this.from4.repassword) {
					_this.$message.error("两次输入密码一致");
					return false;
				}
				_this.from4.id = _this.user.peR_ID;
				_this
					.$confirm("密码修改之后需要重新登录，确定修改吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						EditPassWord(_this.from4).then((res) => {
							if (res.success) {
								_this.$message({
									message: "操作成功",
									type: "success",
								});
								_this.drawer4 = false;
								localStorage.removeItem("token");
								localStorage.removeItem("userinfo");
								_this.resolvewin("/login", {}, 1);
							} else {
								_this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},

			loingout() {
				var _this = this;
				_this
					.$confirm("确定退出吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("userinfo");
						_this.resolvewin("/login", {
							type: 1
						}, 1);
					})
					.catch(() => {});
			},
			opercompanyinfos() {
				var _this = this;
				if (isnull(_this.comfrom.COM_NAME)) {
					_this.$message.error("请输入公司名称!");
					return false;
				}
				if (isnull(_this.comfrom.coM_ACCOUNT)) {
					_this.$message.error("请输入用户名!");
					return false;
				}
				if (isnull(_this.comfrom.coM_PWD)) {
					_this.$message.error("请输入密码!");
					return false;
				}
				if (isnull(_this.comfrom.COM_VOUCHER)) {
					_this.$message.error("请上传营业执照!");
					return false;
				}
				opercompanyinfo(_this.comfrom).then((res) => {
					if (res.success) {
						_this.$message({
							message: "操作成功",
							type: "success",
						});
						_this.comfrom = {
							COM_ID: "", //公司ID
							COM_NAME: "", //公司名
							COM_LINKMAN: "", //联系人
							COM_ZJLB: "", //证件类别
							COM_ZJLB_CODE: "", //证件编号
							COM_MPHONE: "", //手机号码
							COM_PHONE: "", //联系电话
							COM_ZIPCODE: "", //邮编
							COM_NATURE: "", //公司性质
							COM_SCALE: "", //公司规模
							COM_WEBSITE: "", //公司网址
							COM_INDUSTRY: "", //所属行业
							COM_EMAIL: "", //公司邮箱
							COM_HYTYPE: "", //招聘大类
							COM_VOUCHER: "", //营业执照
							COM_INTRODUCT: "", //公司介绍
							COM_ADDRESS: "",
							coM_PWD: "", //密码
							coM_ACCOUNT: "", //账号
						};
						//  _this.updatainfo();
						_this.navChange(0);
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			naventer(i) {
				this.navindexHover = i;
			},
			navleave() {
				this.navindexHover = null;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.drawer-head {
		font-size: 18px;
		font-weight: bold;
	}

	// 修改弹框
	.changeLine {
		position: relative;
		margin-bottom: 10px;

		input {
			width: 360px;
			height: 45px;
			border: 1px solid #ececec;
			box-sizing: border-box;
			padding-left: 50px;
		}

		i {
			color: #dcdfe6;
			position: absolute;
			left: 15px;
			line-height: 45px;
			font-size: 20px;
		}

		.changeCode {
			position: absolute;
			right: 0;
			top: 0;

			img {
				height: 45px;
			}
		}

		.changeTips {
			width: 360px;
			text-align: center;
			color: #fb6703;
			line-height: 45px;
		}
	}

	.project {
		margin-top: 29px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.trainingProgram {
			width: 270px;
			line-height: 20px;
			border-radius: 8px;
			margin: 20px;
			background-color: #fff;

			.trainingProgram_title {
				width: 100%;
				height: 150px;
				background: url(../../assets/img/copy.png) no-repeat top;
				background-size: 100%;
				position: relative;
				cursor: pointer;
				overflow: hidden;
			}

			.trainingProgram_center {
				width: 100%;

				.ttitle {
					margin-top: 19px;
					margin-left: 24px;
					color: #333333;
					font-size: 14px;
					font-family: SourceHanSansSC-regular;
				}

				.signup {
					width: 80px;
					height: 26px;
					border-radius: 13px;
					line-height: 26px;

					background-color: #409EFF;
					color: #fff;
					font-size: 12px;
					text-align: center;
					font-family: Microsoft Yahei;
					cursor: pointer;
				}
			}
		}
	}

	.changeBTN {
		width: 360px;
		margin: 10px auto;
		height: 45px;
		text-align: center;
		line-height: 45px;
		background: #276bf2;
		color: white;
	}

	// tab
	::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		border-radius: 0;
	}

	::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item {
		border: none;
	}

	::v-deep.el-tabs--card>.el-tabs__header {
		border: none;
		margin: 0;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
		width: 170px;
		height: 45px;
		margin: 25px 10px 20px;
		text-align: center;
		box-sizing: border-box;
		border: 1px solid #ececec;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
		color: #276bf2;
		border: 1px solid #276bf2;
	}

	::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item:hover {
		color: #276bf2;
	}

	// 复选框
	.el-checkbox:last-of-type {
		margin-top: 10px;
	}

	::v-deep.el-checkbox:last-of-type .el-checkbox__label {
		font-size: 12px;
		color: #333;

		b {
			color: #276bf2;
		}
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #999;

		b {
			color: #276bf2;
		}
	}

	::v-deep.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #dcdfe6;
	}

	// 认证弹框
	.authTitle {
		font-size: 18px;
		color: #333;
		margin-bottom: 15px;

		img {
			width: 25px;
			vertical-align: -6px;
			margin-right: 10px;
		}
	}

	.authTips {
		text-align: center;
		font-size: 12px;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #f8f8f8;
	}

	.authInput {
		width: 100%;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 15px;

		.authName {
			text-align: right;
			margin-right: 15px;
			width: 80px;
		}

		input {
			width: 240px;
			height: 40px;
			background: #f8f8f8;
			border-radius: 4px;
			text-indent: 1em;
		}

		.send {
			width: 90px;
			height: 40px;
			margin-left: 10px;
			background: #ffece0;
			color: #fb6703;
			border-radius: 4px;
			text-align: center;
			line-height: 40px;
		}
	}

	::v-deep.el-dialog--center .el-dialog__body {
		padding: 0 25px 40px;
	}

	::v-deep.el-dialog__body {
		padding: 0 25px 40px;
	}

	.authBTN {
		width: 80px;
		height: 30px;
		line-height: 30px;
		margin: 0 auto;
		text-align: center;
		margin-top: 20px;
		border-radius: 4px;
		color: white;
		background: #fb6703;
	}

	.authCheck {
		padding-left: 18%;
		margin: 0 auto;
	}

	.warning {
		margin-top: 20px;
		padding-left: 20%;
		font-size: 12px;
		color: #999;
		line-height: 1.8;
		font-size: 12px;

		i {
			margin-right: 10px;
		}
	}

	// 分页
	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			::v-deep.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			::v-deep.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			::v-deep.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	// 头部
	.headLine {
		width: 100%;
		padding: 35px 0 20px;
		background: white;

		.headContain {
			width: 1200px;
			margin: 0 auto;
			font-size: 14px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.headLeft {
				width: 945px;

				.search {
					width: 100%;
					height: 50px;
					line-height: 50px;
					border: 1px solid #ddd;
					box-sizing: border-box;
					position: relative;

					.line {
						height: 20px;
						border-left: 1px solid #bfc2cc;
						margin-right: 30px;
					}

					input {
						width: 500px;
						text-indent: 1em;
					}

					i {
						color: #bfc2cc;
						margin-left: 10px;
						margin-right: 30px;
					}

					.searchBTN {
						position: absolute;
						right: 0;
						top: 0;
						width: 150px;
						height: 100%;
						background: #276bf2;
						color: white;
						text-align: center;
					}
				}

				.headNav {
					margin-top: 20px;

					.navItem {
						margin-right: 90px;

						i {
							color: #bfc2cc;
							margin-left: 20px;
						}
					}
				}
			}

			.headRight {
				text-align: right;

				.tel {
					color: #276bf2;
					margin-bottom: 30px;

					i {
						margin-right: 10px;
					}
				}

				.clear {
					color: #999;
				}
			}
		}
	}

	.box {
		background-color: #fff;
		padding: 15px;
		box-sizing: border-box;

		.box-head {
			font-size: 16px;
			color: #101010;
			height: 45px;
			line-height: 45px;

			a {
				float: right;
				font-size: 12px;
				color: #898989
			}
		}

		.box-body {


			.infoLine {
				margin-top: 10px;
				margin-right: 25px;
				font-size: 14px;
				color: #666;

				i {
					color: #999;
					font-size: 12px;
					margin-right: 5px;
				}
			}
		}
	}

	// 内容
	.contain {
		width: 1200px;
		font-size: 14px;
		color: #999;
		margin: 10px auto 60px;

		.title {
			font-size: 18px;
			color: #333;
			font-weight: bold;
		}

		.edit {
			cursor: pointer;
			color: #fb6703;

			.examine {
				display: flex;
				align-items: center;
				padding-left: 20px;
				font-size: 14px;
				color: #fc9e5f;
				margin-top: 10px;

				img {
					width: 20px;
					margin-right: 8px;
					cursor: text;
				}
			}
		}

		//  左
		.left {
			width: 180px;

			.nav {
				background-color: white;
				width: 100%;
				margin-bottom: 10px;

				.item {
					cursor: pointer;
					width: 100%;
					height: 50px;
					line-height: 50px;
					padding: 0 25px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;

					.name {
						display: flex;
						align-items: center;

						img {
							width: 25px;
							margin-right: 10px;
							height: 25px;
						}
					}
				}

				.itemClick {
					box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
					color: #276bf2;

					i {
						color: #276bf2;
					}
				}

				.item:hover {
					color: #276bf2;
				}
			}
		}


		.right {
			width: 1005px;

			.conter1 {
				width: 690px;
				float: left;

				.complete,
				.progress,
				.privacy {
					width: 100%;
					background: white;
					font-size: 12px;
					color: #666;
					padding: 25px;
					box-sizing: border-box;
					margin-bottom: 10px;

					.el-icon-warning {
						font-size: 21px;
						color: #fb6703;
						margin-left: 10px;
					}

					.tips {
						color: #999;
						margin: 10px 0;
					}

					.completeLine {
						width: 100%;
						padding-top: 25px;

						.lineLeft {
							position: relative;
							padding-left: 15px;
						}

						.round {
							width: 10px;
							height: 10px;
							background: #fb6703;
							border-radius: 50%;
							position: absolute;
							left: 0;
							top: 3px;
						}

						.subTitle {
							font-size: 14px;
							margin-bottom: 10px;
						}

						b {
							color: #fb6703;
						}

						.lineRight {
							color: #fb6703;
						}
					}
				}

				.progress {
					color: #fb6703;

					.progressBar {
						width: 230px;
						height: 10px;
						border-radius: 50px;
						background: #f8f8f8;

						.bar {
							height: 100%;
							background: linear-gradient(to right,
									rgb(250, 190, 0),
									rgb(255, 102, 5));
							border-radius: 50px;
						}
					}

					.text {
						color: #999;
					}
				}

				.privacy {
					.privacyLine {
						margin-top: 30px;
					}

					.privacyRight {
						color: #999;
					}

					.privacyRight:hover {
						cursor: pointer;
					}
				}

				.banner {
					width: 100%;
					height: 160px;
					overflow: hidden;
					margin-bottom: 10px;

					img {
						width: 100%;
					}
				}
			}

			.message {
				display: flex;
				align-items: center;
				padding: 12px 10px;
				background-color: #fff;
				margin-bottom: 10px;
				font-size: 14px;
				color: #999999;
				justify-content: space-between;

				.message-i {
					width: 33px;
					height: 33px;
					margin-right: 10px;
				}

				.message-r {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
			}

			.center {
				float: right;
				width: 300px;

				.preInfo {
					margin-bottom: 10px;
					font-size: 14px;
					color: #333;
					padding: 35px 44px 25px;
					box-sizing: border-box;
					background-color: white;
					text-align: center;

					.avatar-uploader {
						width: 85px;
						height: 85px;
						border-radius: 50%;
						overflow: hidden;
						display: inline-block;

						img {
							width: 100%;
						}
					}

					.name {
						font-size: 16px;
						margin-right: 10px;
						cursor: pointer;
					}

					.infomation {
						width: 100%;

						span {
							font-size: 14px;
							color: #898989;
						}

						.wz {
							b {
								color: #46A1F2;
								font-size: 18px;
							}
						}

						label {
							background-color: #898989;
							height: 18px;
							border-radius: 15px;
							width: 100%;
							display: block;

							span {
								background-color: #46A1F2;
								height: 18px;
								display: block;
								border-radius: 15px;
							}
						}

						.btn {
							line-height: 36px;
							width: 96px;
							border: 1px solid #46A1F2;
						}

						.btn1 {
							background-color: #fff;
							color: #46A1F2
						}

						.btn2 {
							background-color: #46A1F2;
							color: #fff
						}
					}
				}

				.merit .title {
					margin-bottom: 10px;
				}
			}

			.delivery {
				width: 100%;

				.recTab {
					width: 100%;
					height: 60px;
					background: white;
					padding: 0 25px;
					box-sizing: border-box;
					color: #333;
					margin-bottom: 10px;

					.recTabItem {
						height: 100%;
						line-height: 60px;
						margin-right: 50px;
					}

					.recTabItem:hover {
						cursor: pointer;
					}

					.itemClick {
						border-bottom: 4px solid #276bf2;
						box-sizing: border-box;
						color: #276bf2;
					}
				}

				.Dlist {
					width: 100%;
					margin-bottom: 10px;
					background: white;
					padding: 20px 20px 15px;
					box-sizing: border-box;
					font-size: 14px;
					color: #666;
					border: 1px solid #eee;

					.DlistName {
						font-size: 16px;
						color: #101010;
						margin-right: 15px;
					}

					.DlistSalary {
						font-size: 16px;
						color: #FF6000;
						margin-right: 20px;
					}

					.DlistDate {
						span {
							margin-left: 10px;
						}
					}


					.DlistFactor {
						font-size: 14px;
						color: #898989;
					}

					.DlistWelfare {
						padding: 2px 4px;
						background: #f8f8f8;
						color: #898989;
						font-size: 14px;
						margin-right: 10px;
					}

					text-align: right;

					.DlistCo {
						font-size: 16px;
						color: #46A1F2
					}

					.DlistHot {
						margin-bottom: 10px;

						b {
							color: #1177dc;
						}
					}

					.DlistBtn,
					.DlistDel {
						width: 105px;
						height: 35px;
						background: #cecece;
						color: white;
						line-height: 35px;
						text-align: center;
						margin-left: auto;
						border-radius: 4px;
					}

					.DlistDel {
						background: #276bf2;
					}

				}

				.Dlist:hover {
					cursor: pointer;
					box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
				}
			}

			.moreBtn {
				width: 100%;
				height: 50px;
				line-height: 50px;
				color: white;
				background: #276bf2;
				text-align: center;
			}

			.moreBtn:hover {
				cursor: pointer;
			}

			.mainTitle {
				width: 100%;
				padding: 20px 30px;
				box-sizing: border-box;
				background: white;
				margin-bottom: 10px;
				font-size: 14px;
				color: #333;

				.main {
					font-size: 28px;
					margin-bottom: 10px;
				}
			}

			.module {
				width: 100%;
				box-sizing: border-box;
				background: white;
				padding: 20px;
				font-size: 14px;
				color: #333;
				margin-bottom: 10px;

				.module-left {
					.pic {
						width: 85px;
						height: 85px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 20px;

						img {
							width: 100%;
						}
					}
				}

				.module-right {
					flex: 1;
					width: 100%;

					.name {
						color: #101010;
						font-size: 20px;
						text-align: left;
						font-family: SourceHanSansSC-black;
						font-weight: bold;
						margin-bottom: 10px;

						span {
							font-size: 14px;
							margin-left: 25px;
						}

						a {
							font-weight: normal;
							font-size: 14px;
							color: #0089FF;
							cursor: pointer;
						}
					}

					span {
						margin-right: 10px;
					}

					i {
						font-size: 24px;
					}

					.release-time {
						width: 100%;
						font-size: 14px;
						color: #787878;
						margin-bottom: 12px;
						height: 26px;
						line-height: 26px;
					}
				}


				.moduleEdit {
					color: #666;
					cursor: pointer;
					color: #0089FF;
				}

				.moduleTitle {
					font-size: 16px;
					color: #333;
					font-weight: bold;
					//margin-bottom: 25px;

					img {
						width: 30px;
						margin-right: 10px;
					}
				}

				textarea {
					width: 680px;
					height: 40px;
					border: 1px solid #ececec;
					box-sizing: border-box;
					padding: 0;
					border-radius: 4px;
					text-indent: 1em;
					padding-top: 10px;
				}

				.label {
					width: 100%;

					.labelLine {
						width: 50%;
						margin-bottom: 20px;
						position: relative;

						.labelName {
							width: 90px;
						}

						input {
							height: 40px;
							box-sizing: border-box;
							border: 1px solid rgba(193, 189, 189, 0.4);
							border-radius: 4px;
							text-indent: 1em;
							width: 260px;
						}

						b {
							color: red;
						}

						i {
							color: #bfc2cc;
							position: absolute;
							left: 325px;
						}
					}

					.labelItem {
						font-size: 14px;
						color: #898989;
						line-height: 1.8;
					}

					.expect {
						margin-top: 10px;
						padding: 0px;
						box-sizing: border-box;
						line-height: 30px;
						color: #101010;

						.expectName {
							font-weight: bold;
							color: #101010;
						}

						.expectSalary {
							color: #101010;
						}

						b {
							margin: 0 10px;
						}

						.expectEdit {
							cursor: pointer;
							margin-right: 20px;
							color: #0089FF;
						}

						.expectDel {
							cursor: pointer;
							color: #0089FF;
						}

						.expectCont {
							font-size: 13px;
							color: #898989;
						}

						.expectLeft {
							width: 50%;
							color: #898989;
						}
					}
				}

				.moduleBTN {
					display: flex;
					justify-content: center;
					margin-top: 20px;

					.cancel,
					.preservation {
						width: 200px;
						height: 35px;
						line-height: 35px;
						text-align: center;
						margin: 0 15px;
					}

					.cancel {
						cursor: pointer;
						border: 1px solid #276bf2;
						box-sizing: border-box;
						color: #276bf2;
					}

					.preservation {
						cursor: pointer;
						background: #276bf2;
						color: white;
					}
				}
			}

			.browse {
				width: 100%;
				padding: 15px 30px;
				box-sizing: border-box;
				font-size: 14px;
				color: #999;
				margin-bottom: 10px;
				background: white;

				.browsePic {
					width: 36px;
					height: 36px;
					margin-right: 15px;
					overflow: hidden;
					border-radius: 50%;

					img {
						width: 100%;
					}
				}

				.browseName {
					color: #333;
				}

				.browseBox {
					margin-left: 50px;
					margin-top: 10px;
					background: #f8f8f8;
					padding: 15px;
					box-sizing: border-box;

					.browsePost {
						color: #276bf2;
						font-size: 16px;
						margin-right: 15px;
					}

					.browseDate {
						color: #666;
					}

					.browseSalary {
						color: #fb6703;
						margin-right: 25px;
					}

					.browseFactor {
						margin-right: 10px;
					}

					.browseBoxRight {
						text-align: right;

						.browseCo {
							font-size: 18px;
							color: #666;
							margin-bottom: 15px;
						}

						.coFactor {
							margin-left: 10px;
						}
					}
				}
			}

			.browse:hover {
				box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
			}

			.subNav {
				width: 100%;
				height: 65px;
				background: white;
				padding: 0 20px;
				box-sizing: border-box;
				color: #666;
				margin-bottom: 10px;

				.subNavItem {
					height: 35px;
					line-height: 35px;
					margin-right: 30px;
				}

				.subNavItem:hover {
					cursor: pointer;
				}

				.subNavItemClick {
					color: #276bf2;
					border-bottom: 4px solid #276bf2;
					box-sizing: border-box;
				}
			}

			.course {
				width: 100%;
				padding: 20px 25px;
				box-sizing: border-box;
				background: white;
				color: #666;
				margin-bottom: 10px;

				.courseBanner {
					width: 210px;
					height: 125px;
					margin-right: 35px;
					overflow: hidden;

					img {
						width: 100%;
					}
				}

				.courseRight {
					width: 585px;
				}

				.courseTitle {
					color: #333;
					font-weight: bold;
					margin-bottom: 10px;
				}

				.courseDate {
					margin-top: 30px;
				}
			}

			.course:hover {
				box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
			}

			.saveBox {
				width: 100%;
				background: white;
				padding: 0 25px 40px;
				box-sizing: border-box;

				.saveLine {
					height: 70px;
					border-bottom: 1px solid #f8f8f8;
					box-sizing: border-box;

					.saveIcon {
						width: 26px;
						height: 26px;
						margin-right: 20px;

						img {
							width: 100%;
						}
					}

					.saveTitle {
						width: 130px;
						color: #333;
					}

					.saveCont {
						width: 625px;
						color: #333;
					}

					.already {
						color: #fb6703;
					}

					.saveEdit {
						color: #276bf2;
						cursor: pointer;
					}
				}

				.saveBTN {
					width: 260px;
					height: 35px;
					line-height: 35px;
					text-align: center;
					color: #276bf2;
					border: 1px solid #276bf2;
					box-sizing: border-box;
					margin: 100px auto 0;
					cursor: pointer;
				}
			}
		}
	}

	.el-textarea textarea {
		height: 300px;
	}

	.table_from {
		padding-top: 20px;

		.el-input {
			width: 80%;
		}
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}


	.state {
		font-size: 16px;
	}

	.recommend {
		width: 100%;

		.recTab {
			width: 100%;
			height: 60px;
			background: white;
			padding: 0 25px;
			box-sizing: border-box;
			color: #333;
			margin-bottom: 10px;

			.recTabItem {
				height: 100%;
				line-height: 60px;
				margin-right: 50px;
			}

			.itemClick {
				border-bottom: 4px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
			}

			.recTabItem:hover {
				cursor: pointer;
			}
		}

		.resume {
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			background: white;
			margin-bottom: 10px;

			.resumeLeft {
				width: 88%;

				.left {
					width: 60%;
					display: flex;
					border-right: 1px solid #edf1f5;

					.pic {
						width: 85px;
						height: 85px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 20px;

						img {
							width: 100%;
						}
					}

					.personal {
						display: flex;
						flex-direction: column;
						justify-content: center;

						.name {
							color: #276bf2;
							font-size: 16px;
							margin-bottom: 15px;

							i {
								color: rgb(220, 0, 29);
								margin-left: 10px;
							}

							.brisk {
								font-size: 14px;
								color: #666;
								margin-left: 25px;
							}
						}

						.salary {
							margin-right: 20px;
							color: #ff7630;
							font-size: 16px;
						}

						.resumeFactor {
							margin-right: 10px;
						}
					}
				}

				.right {
					width: 40%;
					margin-left: 20px;

					.state {
						color: #666;
						font-size: 14px;

						.top {
							margin-bottom: 15px;
						}
					}
				}
			}

			.resumeRight {
				width: 12%;
				font-size: 14px;

				.talk {
					color: #fb6703;
					margin-bottom: 10px;

					img {
						width: 22px;
						height: 22px;
						margin-right: 5px;
					}
				}

				.collect {
					color: #276bf2;

					i {
						margin-right: 5px;
					}

					.iconoff {
						font-size: 24px;
					}

					.iconon {
						font-size: 28px;
					}
				}

				.talk:hover,
				.collect:hover {
					cursor: pointer;
				}
			}
		}

		.resume:hover {
			box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
		}
	}

	.per_status {
		display: flex;
		align-items: center;
		position: relative;
		height: 30px;

		img {
			width: 40px;
			height: 40px;
			position: absolute;
		}

		span {
			padding: 0 20px 0 50px;
		}
	}

	.yc {
		color: #276bf2;
		background-color: #276bf233;
		border-radius: 50px;
	}

	.xs {
		color: #3abda3;
		background-color: #3abda33a;
		border-radius: 50px;
	}

	.per_status_label {
		font-size: 15px;
		margin-top: 15px;
		color: #999;
		text-align: center;
	}

	.RequiredFlag {
		position: absolute;
		margin-top: 15px;
		margin-left: 5px;
		color: red;
	}


	// .RequiredLable label {
	// 	width: 70px;
	// 	text-align-last: justify;
	// 	padding: 0 0 0 12px;
	// 	text-align: unset;
	// }
	
	.messagedetail .el-dialog__header {
		padding: 10px;
	}

	.messagedetail .el-dialog__headerbtn {
		top: 10px
	}

	.messagedetail .el-dialog__body {
		padding: 0 !important;
	}
</style>
<style lang="scss" scoped>

	// .scrolly {}

	.messagedetail {
		box-sizing: border-box;

		.message-left {
			background-color: #e2e0df;
			box-sizing: border-box;
			width: 250px;
			height: 600px;
			overflow-y: auto;

			.message-list {
				.item {
					padding: 10px;
					line-height: 24px;
					position: relative;

					.mc {
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;

						a {
							position: absolute;
							bottom: 10px;
							right: 5px;
						}
					}

					.Logo {
						width: 50px;
						height: 50px;
						border-radius: 50px;
						overflow: hidden;

						img {
							width: 50px;
							height: 50px;
						}
					}

					.name {
						color: #101010;
						overflow: hidden;
						width: 60%;
						word-break: keep-all;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.time {
						color: #898989
					}

					.info {
						color: #898989;
						overflow: hidden;
						width: 80%;
						word-break: keep-all;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.badge {
						margin-right: 15px;

						.badge-i {
							background-color: #0089FF;
							width: 17px;
							height: 17px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							border-radius: 50px;
						}
					}
				}

				.item:hover {
					background-color: #c6c6c5;
				}
			}
		}

		.message-right {
			background-color: #f5f5f5;
			box-sizing: border-box;
			width: 610px;
		}
	}

	.message-box {
		.message-title {
			line-height: 40px;
			padding: 0 10px;
		}

		// 内容
		.message-detail {
			display: flex;
			flex-direction: column;
			position: relative;
			background: #ccc;
			height: 520px;
			overflow-y: auto;

			.message-scroll {
				flex: 1;
				padding: 10px;
			}
		}

		.postList {
			margin: 10px;
			background: white;
			font-size: 14px;
			padding: 15px;

			image {
				width: 30px;
				height: 18px;
			}

			.tel {
				width: 85%;
				font-size: 14px;
				color: #333;
			}

			.portrait {
				width: 30px;
				height: 30px;
				border-radius: 50px;
				overflow: hidden;
				margin-right: 10px;
				line-height: 30px;
				text-align: center;
				color: #fff;

				image {
					width: 30px;
					height: 30px;
				}
			}

			.factor {
				background: #f5f8ff;
				padding: 3px 6px;
				color: #1977fb;
				font-size: 14px;
				margin: 10px 0;
				margin-right: 10px;
			}

			.postInfo {
				font-size: 14px;
				color: #666;
				margin-top: 10px;
			}

			.name {
				font-weight: bold;
				color: #333;
				margin-right: 10px;
			}

			.salarr {
				color: #ff6615;
				font-weight: bold;
			}
		}

		.time {
			font-size: 14px;
			color: #666;
			text-align: center;
			margin: 15px 0;
		}

		.popup {
			width: 280px;
			margin: 10px auto;
			border-radius: 10px;
			background: white;
			padding: 15px 20px 0;
			box-sizing: border-box;
			font-size: 14px;
			color: #333;

			.pic {
				width: 40px;
				height: 40px;
				border-radius: 4px;
				overflow: hidden;
				margin-right: 10px;

				image {
					width: 40px;
					height: 40px;
				}
			}

			.flex1 {
				padding-bottom: 15px;
			}

			.btn {
				border-top: 1px solid #eee;
				padding: 10px;

				.btnlist {
					width: 50%;
					text-align: center;
					font-size: 14px;
					color: #1c7efe;
				}

				.btnlist:first-child {
					border-right: 1px solid #eee;
				}
			}
		}

		.message-hr-img {
			margin-right: 12px;
		}

		.message-hr-news {
			padding: 20px;
			max-width: 450px;
			border-radius: 10px;
			overflow: hidden;
			background: #fff;
			color: #000;
		}

		.message-hr-news text {
			table-layout: fixed;
			word-wrap: break-all;
			word-break: normal;
			overflow: hidden;
		}

		.message-me {
			display: flex;
			justify-content: flex-end;
		}

		.message-me-img {
			margin-left: 12px;
		}

		.message-me-news {
			padding: 20px;
			max-width: 450px;
			border-radius: 10px;
			overflow: hidden;
			background: #1c7efe;
			color: #fff;
		}

		.message-me-news text {
			table-layout: fixed;
			word-wrap: break-word;
			word-break: normal;
			overflow: hidden;
		}

		.dialog {
			margin: 10px 0;
			display: flex;
			align-items: top;
			padding: 0;

			.portrait {
				width: 30px;
				height: 30px;
				border-radius: 50px;
				text-align: center;
				line-height: 30px;
				margin-top: 5px;
				overflow: hidden;
				color: #fff;

				img {
					width: 100%;
					height: 100%;
				}

				image {
					width: 30px;
					height: 30px;
				}
			}

			.dialogCont {
				max-width: 225px;
				padding: 10px;
				box-sizing: border-box;
				font-size: 14px;
				border-radius: 4px;
			}
		}

		.writing {
			width: 100%;
			background: white;

			input {
				height: 40px;
				width: 100%;
				background: #f8f8f8;
				border: none;
				padding: 0;
				text-indent: 1em;
				font-size: 14px;
				color: #666;
				border-radius: 2px;
			}
		}

		.mf-icon {
			height: 40px;
			width: 60px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			background-color: #1389ff;
			color: #FFFFFF;
			border: none;
			border-radius: 0px;
		}

		.more {
			font-size: 14px;
			color: #333;
			line-height: 45px;
			text-align: center;
		}
	}

	.qycolor0 {
		background-color: #0098fe;
	}

	.qycolor1 {
		background-color: #fe7270;
	}

	.qycolor2 {
		background-color: #ff9a65;
	}

	.qycolor3 {
		background-color: #6bb7b2;
	}

	.qycolor4 {
		background-color: #9bcd35;
	}

	.qycolor5 {
		background-color: #db4e36;
	}

	.qycolor6 {
		background-color: #01a758;
	}

	.qycolor7 {
		background-color: #8c53e6;
	}

	.qycolor8 {
		background-color: #5ed9a8;
	}

	.qycolor9 {
		background-color: #49c2e8;
	}
</style>
